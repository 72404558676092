import React, { useState, useEffect } from 'react';
import { mainAuth, db } from '../services/firebase';
import { getIdToken } from 'firebase/auth';
import BordereauFile from './BordereauFile';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { Typography, Box, List, ListItem, ListItemText, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, Collapse, Paper, Grid, Divider, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { useDashboard } from '../contexts/DashboardContext';
import { styled } from '@mui/material/styles';
import ContractEditor from './ContractEditor';
import axios from 'axios';
import { parse, format, compareAsc } from 'date-fns';
import { TreeView, TreeItem } from '@mui/lab';
import CustomTooltip from './CustomTooltip';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

function ManageContracts({ companyName }) {
  const { wholesalerContractMap, setWholesalerContractMap } = useDashboard();
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [expandedContract, setExpandedContract] = useState(null);
  const [editingContract, setEditingContract] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [error, setError] = useState('');
  const [premiumBordereaux, setPremiumBordereaux] = useState({});
  const [claimBordereaux, setClaimBordereaux] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBordereaux = async () => {
      if (selectedWholesaler) {
        const contractsRef = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts`);
        const contractsSnapshot = await getDocs(contractsRef);
        const premiumPromises = contractsSnapshot.docs.map(async (contractDoc) => {
          const premiumRef = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${contractDoc.id}/raw_premium_bordereaux`);
          const premiumSnapshot = await getDocs(premiumRef);
          return { 
            contractId: contractDoc.id, 
            files: premiumSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          };
        });
        const claimPromises = contractsSnapshot.docs.map(async (contractDoc) => {
          const claimRef = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${contractDoc.id}/raw_claim_bordereaux`);
          const claimSnapshot = await getDocs(claimRef);
          return { 
            contractId: contractDoc.id, 
            files: claimSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
          };
        });
        const premiumResults = await Promise.all(premiumPromises);
        const claimResults = await Promise.all(claimPromises);
        const premiumObj = {};
        const claimObj = {};
        premiumResults.forEach(result => {
          premiumObj[result.contractId] = result.files;
        });
        claimResults.forEach(result => {
          claimObj[result.contractId] = result.files;
        });
        setPremiumBordereaux(premiumObj);
        setClaimBordereaux(claimObj);
      }
    };

    fetchBordereaux();
  }, [selectedWholesaler, companyName]);

  const handleExpand = (contractId) => {
    setExpandedContract(expandedContract === contractId ? null : contractId);
  };

  const handleEdit = (contract) => {
    setEditingContract(contract);
  };

  const handleSave = (updatedContract) => {
    setWholesalerContractMap(prevMap => {
      const updatedContracts = prevMap[selectedWholesaler].contracts.slice();
      const existingContractIndex = updatedContracts.findIndex(c => c.id === updatedContract.id);
      
      if (existingContractIndex !== -1) {
        updatedContracts[existingContractIndex] = updatedContract;
      } else {
        updatedContracts.push(updatedContract);
      }
  
      return {
        ...prevMap,
        [selectedWholesaler]: {
          ...prevMap[selectedWholesaler],
          contracts: updatedContracts
        }
      };
    });
    setEditingContract(null);
  };

  const handleCancel = () => {
    setEditingContract(null);
  };

  const handleDelete = (contract) => {
    setDeleteConfirmation(contract);
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation) return;

    setIsLoading(true);
    try {
      const contractRef = doc(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts`, deleteConfirmation.id);
      await deleteDoc(contractRef);

      setDeleteConfirmation(null);
      
      setWholesalerContractMap(prevMap => ({
        ...prevMap,
        [selectedWholesaler]: {
          ...prevMap[selectedWholesaler],
          contracts: prevMap[selectedWholesaler].contracts.filter(c => c.id !== deleteConfirmation.id)
        }
      }));
    } catch (err) {
      setError('Failed to delete contract');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteBordereaux = (contractId, type, fileId) => {
    if (type === 'premium') {
      setPremiumBordereaux(prev => ({
        ...prev,
        [contractId]: prev[contractId].filter(file => file.id !== fileId)
      }));
    } else {
      setClaimBordereaux(prev => ({
        ...prev,
        [contractId]: prev[contractId].filter(file => file.id !== fileId)
      }));
    }
  };

  const parseDate = (dateString, dateFormat) => {
    if (!dateString) return null;
    
    const usFormat = 'MM/dd/yyyy';
    const ukFormat = 'dd/MM/yyyy';
    
    // Try the specified format first
    const primaryFormat = dateFormat === 'US' ? usFormat : ukFormat;
    const secondaryFormat = dateFormat === 'US' ? ukFormat : usFormat;
    
    try {
      return parse(dateString, primaryFormat, new Date());
    } catch (error) {
      // If the primary format fails, try the secondary format
      try {
        return parse(dateString, secondaryFormat, new Date());
      } catch (secondError) {
        console.warn(`Failed to parse date: ${dateString}`);
        return null;
      }
    }
  };

  function renderSchema(schema) {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandLessIcon />}
        defaultExpandIcon={<ExpandMoreIcon />}
      >
        {schema.map((node, index) => renderSchemaNode(node, '', index))}
      </TreeView>
    );
  }

  function renderSchemaNode(node, parentId = '', index = 0) {
    const nodeId = parentId ? `${parentId}-${index}` : `${index}`;
    if (!node.children || node.children.length === 0) {
      return (
        <TreeItem key={nodeId} nodeId={nodeId} label={node.name} />
      );
    } else {
      return (
        <TreeItem key={nodeId} nodeId={nodeId} label={node.name}>
          {node.children.map((child, childIndex) => renderSchemaNode(child, nodeId, childIndex))}
        </TreeItem>
      );
    }
  }

  function renderMappings(mappings) {
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Database Column</TableCell>
              <TableCell>Mapped Leaf Node(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(mappings).map(([dbColumn, mappingValue]) => (
              <TableRow key={dbColumn}>
                <TableCell>{dbColumn}</TableCell>
                <TableCell>{mappingValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

const renderContractDetails = (contract) => {
  const sortedPremiumBordereaux = [...(premiumBordereaux[contract.id] || [])].sort((a, b) => {
    const dateA = parseDate(a.date, contract.date_format);
    const dateB = parseDate(b.date, contract.date_format);
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return compareAsc(dateA, dateB);
  });
  const sortedClaimBordereaux = [...(claimBordereaux[contract.id] || [])].sort((a, b) => {
    const dateA = parseDate(a.date, contract.date_format);
    const dateB = parseDate(b.date, contract.date_format);
    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;
    return compareAsc(dateA, dateB);
  });

  const groupedBordereaux = {};
  sortedPremiumBordereaux.forEach(file => {
    const date = parseDate(file.date, contract.date_format);
    const monthYear = date ? format(date, 'MMMM yyyy') : 'Undetermined';
    if (!groupedBordereaux[monthYear]) {
      groupedBordereaux[monthYear] = { premium: [], claim: [] };
    }
    groupedBordereaux[monthYear].premium.push(file);
  });
  sortedClaimBordereaux.forEach(file => {
    const date = parseDate(file.date, contract.date_format);
    const monthYear = date ? format(date, 'MMMM yyyy') : 'Undetermined';
    if (!groupedBordereaux[monthYear]) {
      groupedBordereaux[monthYear] = { premium: [], claim: [] };
    }
    groupedBordereaux[monthYear].claim.push(file);
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <SectionTitle>Premiums Schema:</SectionTitle>
        {contract.premiums_schema && contract.premiums_schema.length > 0 ? (
          renderSchema(contract.premiums_schema)
        ) : (
          <Typography>No Premiums Schema Defined</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionTitle>Claims Schema:</SectionTitle>
        {contract.claims_schema && contract.claims_schema.length > 0 ? (
          renderSchema(contract.claims_schema)
        ) : (
          <Typography>No Claims Schema Defined</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionTitle>Premiums Mappings:</SectionTitle>
        {contract.premiums_mappings && Object.keys(contract.premiums_mappings).length > 0 ? (
          renderMappings(contract.premiums_mappings)
        ) : (
          <Typography>No Premiums Mappings Defined</Typography>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <SectionTitle>Claims Mappings:</SectionTitle>
        {contract.claims_mappings && Object.keys(contract.claims_mappings).length > 0 ? (
          renderMappings(contract.claims_mappings)
        ) : (
          <Typography>No Claims Mappings Defined</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <SectionTitle>Date Format:</SectionTitle>
        <Typography>{contract.date_format || 'Not set'}</Typography>
        <Divider sx={{ my: 2 }} />
        <SectionTitle>Syndicate:</SectionTitle>
        <Typography>{contract.syndicate_id || 'Not assigned'}</Typography>
        <Divider sx={{ my: 2 }} />
        <SectionTitle>Commissions:</SectionTitle>
        {contract.commissions && Object.keys(contract.commissions).length > 0 ? (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Class of Business</TableCell>
                  <TableCell>Commission (%)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(contract.commissions).map(([classOfBusiness, commission]) => (
                  <TableRow key={classOfBusiness}>
                    <TableCell>{classOfBusiness}</TableCell>
                    <TableCell>{commission}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No Commissions Defined</Typography>
        )}
        <Divider sx={{ my: 2 }} />
        <SectionTitle>Uploaded Bordereaux:
          <CustomTooltip title="Uploaded Bordereaux" content="Here you can see the bordereaux that have been uploaded for this contract. You can view the ingestion status of each sheet inside each uploaded file by expanding the file's cell. You can delete individual bordereaux - this will undo the changes made to the database by this file and its sheets." />
        </SectionTitle>
        {Object.entries(groupedBordereaux).map(([monthYear, files]) => (
          <React.Fragment key={monthYear}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              {monthYear}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SectionTitle>Claims</SectionTitle>
                <List dense>
                  {files.claim.map((file) => (
                    <BordereauFile 
                      key={file.id} 
                      file={file} 
                      onDelete={(fileId) => handleDeleteBordereaux(contract.id, 'claim', fileId)}
                      dateFormat={contract.date_format} 
                    />
                  ))}
                </List>
              </Grid>
              <Grid item xs={12} md={6}>
                <SectionTitle>Premiums</SectionTitle>
                <List dense>
                  {files.premium.map((file) => (
                    <BordereauFile 
                      key={file.id} 
                      file={file} 
                      onDelete={(fileId) => handleDeleteBordereaux(contract.id, 'premium', fileId)}
                      dateFormat={contract.date_format}
                    />
                  ))}
                </List>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

return (
  <Box>
    <Typography component="h2" variant="h6" gutterBottom>
      Manage Contracts
    </Typography>
    {error && <Typography color="error">{error}</Typography>}
    <Select
      value={selectedWholesaler}
      onChange={(e) => setSelectedWholesaler(e.target.value)}
      displayEmpty
      fullWidth
      sx={{ mb: 2 }}
    >
      <MenuItem value="" disabled>Select Wholesaler</MenuItem>
      {Object.keys(wholesalerContractMap).map((wholesalerId) => (
        <MenuItem key={wholesalerId} value={wholesalerId}>
          {wholesalerContractMap[wholesalerId].name}
        </MenuItem>
      ))}
    </Select>
    {selectedWholesaler && (
      <List>
        {wholesalerContractMap[selectedWholesaler].contracts.map((contract) => (
          <StyledPaper key={contract.id} elevation={2}>
            <ListItem>
              <ListItemText primary={contract.id} />
              <IconButton onClick={() => handleEdit(contract)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(contract)}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={() => handleExpand(contract.id)}>
                {expandedContract === contract.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItem>
            <Collapse in={expandedContract === contract.id} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                {renderContractDetails(contract)}
              </Box>
            </Collapse>
          </StyledPaper>
        ))}
      </List>
    )}
    {selectedWholesaler && (
      <Button
        startIcon={<AddIcon />}
        onClick={() => setEditingContract({ id: '', premiums_schema: [], claims_schema: [], premiums_mappings: {}, claims_mappings: {}, syndicate_id: '', date_format: '' })}
        sx={{ mt: 2 }}
      >
        Add New Contract
      </Button>
    )}
    <Dialog
      open={!!deleteConfirmation}
      onClose={() => setDeleteConfirmation(null)}
    >
      <DialogTitle>Confirm Deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {deleteConfirmation?.id}? All data and bordereaux for this contract will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDeleteConfirmation(null)} color="primary">Cancel</Button>
        <Button onClick={confirmDelete} color="primary">Delete</Button>
      </DialogActions>
    </Dialog>
    {editingContract && (
      <Dialog open={!!editingContract} maxWidth="md" fullWidth>
        <DialogContent>
          <ContractEditor
            companyName={companyName}
            wholesalerId={selectedWholesaler}
            contractId={editingContract.id}
            onSave={handleSave}
            onCancel={handleCancel}
          />
        </DialogContent>
      </Dialog>
    )}
    {isLoading && <CircularProgress />}
  </Box>
);
}

export default ManageContracts;