import React, { useState } from 'react';
import { mainAuth } from '../services/firebase';
import { getIdToken } from 'firebase/auth';
import { Typography, ListItem, ListItemText, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, CircularProgress, Collapse, List } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { styled } from '@mui/material/styles';
import { FetchSubmitButton } from './Buttons';
import axios from 'axios';
import { parse, format } from 'date-fns';


const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
      fontWeight: 500,
      wordBreak: 'break-word',
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.text.secondary,
    },
}));

function BordereauFile({ file, onDelete, dateFormat }) {
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    const idToken = await getIdToken(mainAuth.currentUser);
    const headers = {
      Authorization: `Bearer ${idToken}`
    };
    try {
      await axios.post('https://europe-west2-staging-insurance.cloudfunctions.net/delete_bordereaux', {
        document_path: `${file.path}/${file.id}`
      }, { headers });

      await onDelete(file.id);
    } catch (error) {
      console.error('Error deleting file:', error);
    } finally {
      setIsDeleting(false);
      setDeleteConfirmation(false);
    }
  };

  const getFileStatusIcon = () => {
    const statuses = file.cleaned_sheets.map(sheet => sheet.status);
    if (statuses.includes('Processing')) {
      return <CircularProgress size={20} />;
    } else if (statuses.every(status => status === 'Completed')) {
      return <CheckCircleIcon color="success" />;
    } else if (statuses.every(status => status === 'Failed')) {
      return <ErrorIcon color="error" />;
    } else {
      return <WarningIcon color="warning" />;
    }
  };

  const getSheetStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <CheckCircleIcon color="success" />;
      case 'Pending':
      case 'Processing':
        return <CircularProgress size={20} />;
      case 'Failed':
        return <ErrorIcon color="error" />;
      default:
        return null;
    }
  };

  const parseDate = (dateString) => {
    if (!dateString || dateString.trim() === '') {
      return null;
    }
    
    const usFormat = 'MM/dd/yyyy';
    const ukFormat = 'dd/MM/yyyy';
    
    const primaryFormat = dateFormat === 'US' ? usFormat : ukFormat;
    const secondaryFormat = dateFormat === 'US' ? ukFormat : usFormat;
    
    try {
      return parse(dateString, primaryFormat, new Date());
    } catch (error) {
      try {
        return parse(dateString, secondaryFormat, new Date());
      } catch (secondError) {
        console.warn(`Failed to parse date: ${dateString}`);
        return null;
      }
    }
  };

  const formatDate = (dateString) => {
    const parsedDate = parseDate(dateString);
    if (!parsedDate) {
      return '-';
    }
    return format(parsedDate, 'MM/dd/yyyy');
  };

  return (
    <Paper elevation={3} style={{ margin: '10px 0', padding: '10px' }}>
      <StyledListItem>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <StyledListItemText 
          primary={file.fileName} 
          secondary={formatDate(file.date)}
        />
        {getFileStatusIcon()}
        <IconButton onClick={() => setDeleteConfirmation(true)}>
          <DeleteIcon />
        </IconButton>
      </StyledListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {file.cleaned_sheets.map((sheet, index) => (
            <ListItem key={index} style={{ paddingLeft: 32 }}>
              <ListItemText primary={sheet.sheet_name} />
              {getSheetStatusIcon(sheet.status)}
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Dialog 
        open={deleteConfirmation} 
        onClose={() => !isDeleting && setDeleteConfirmation(false)}
        PaperProps={{
          style: {
            opacity: 1,
          },
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {file.fileName}? This will delete this document from our system, and remove its data from our database. It cannot be undone, though you can always re-upload.
          </DialogContentText>
          {isDeleting && (
            <CircularProgress 
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -10,
                marginLeft: -10,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
        <FetchSubmitButton onClick={() => setDeleteConfirmation(false)} disabled={isDeleting} color="primary">
          No
        </FetchSubmitButton>
        <FetchSubmitButton onClick={handleDelete} color="primary" disabled={isDeleting}>
          Yes
        </FetchSubmitButton>
      </DialogActions>
    </Dialog>
    </Paper>
  );
}

export default BordereauFile;