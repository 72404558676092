// AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { signInWithCustomToken, signOut } from 'firebase/auth';
import { mainApp, mainAuth, platformApp, platformAuth, db } from '../services/firebase';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUserMain, setCurrentUserMain] = useState(null);
  const [currentUserPlatform, setCurrentUserPlatform] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const receiveMessage = async (event) => {
      const { mainCustomToken, platformCustomToken } = event.data;
      console.log("Main app: ", mainApp.options.projectId);
      console.log("Platform app: ", platformApp.options.projectId);
      if (mainCustomToken && platformCustomToken) {
        try {
          await signInWithCustomToken(mainAuth, mainCustomToken);
          await signInWithCustomToken(platformAuth, platformCustomToken);

          window.removeEventListener('message', receiveMessage);
        } catch (error) {
          console.error('Error signing in with custom tokens:', error);
          console.log("Main app: ", mainApp.options.projectId);
          console.log("Platform app: ", platformApp.options.projectId);
        }
      }
    };

    window.addEventListener('message', receiveMessage, false);

    const unsubscribeMain = mainAuth.onAuthStateChanged(async (user) => {
      setCurrentUserMain(user || null);
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        // TODO: Make this dynamic fix idTokenResult.claims.organisation
        setCompanyName('intrepid');
      } else {
        setCompanyName(null);
      }
    });

    const unsubscribePlatform = platformAuth.onAuthStateChanged(async (user) => {
      setCurrentUserPlatform(user || null);
    });

    return () => {
      unsubscribeMain();
      unsubscribePlatform();
      window.removeEventListener('message', receiveMessage);
    };
  }, []);

  useEffect(() => {
    if (currentUserMain !== null && currentUserPlatform !== null) {
      setLoading(false);
    }
  }, [currentUserMain, currentUserPlatform]);

  function logout() {
    setCompanyName(null);
    return Promise.all([signOut(mainAuth), signOut(platformAuth)]);
  }

  const value = {
    currentUser: currentUserPlatform,
    currentUserMain,
    currentUserPlatform,
    companyName,
    logout,
    db,
    platformAuth,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
